import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Components & UI
import ProfileLayout from 'components/layouts/profile/profile.layout';
import FamilyAccountCardList from 'display-components/family-management/family-account-card-list';
import MyCaregiversCardList from 'display-components/family-management/my-caregivers-card-list';

// Interfaces and Types
import { FamilyAccountCardProps, MyCaregiversCardProps } from 'types/family-management';

// Styles
import '../family-account-page.style.scss';
import ToastBox from 'ui-kit/toast-box/toast-box';

const familyMembers: FamilyAccountCardProps[] = [
    {
        familyMemberName: 'Alexa Smith',
        familyMemberAge: '76',
        dob: '01/01/1947',
        accountType: 'adult',
        accountStatus: 'invitePending',
        dateRequested: '10/10/2023',
        expiredDate: '12/12/2023',
        onResendInvitationFamilyMember: () => {},
        onCancelInvitationFamilyMember: () => {}
    },
    {
        familyMemberName: 'Jay Smith ',
        familyMemberAge: '17',
        dob: '01/01/2006',
        insuranceId: 'C9233882945',
        accountType: 'Child',
        accountStatus: 'fullAccess',
        note: 'The account type will change from Minor to Adult in [X] days.',
        onRemoveFamilyMember: () => {}
    },
    {
        familyMemberName: 'Max Smith',
        familyMemberAge: '4',
        dob: '01/01/2019',
        insuranceId: 'C9233882945',
        accountType: 'pet',
        accountStatus: 'fullAccess',
        onRemoveFamilyMember: () => {}
    },
    {
        familyMemberName: 'Carry West',
        dob: '01/01/1994',
        insuranceId: 'C9233882945',
        accountType: 'adult',
        accountStatus: 'partialAccess',
        onRemoveFamilyMember: () => {}
    },
    {
        familyMemberName: 'Andrew Brown',
        accountType: 'adult',
        accountStatus: 'declined',
        onResendInvitationFamilyMember: () => {}
    },
    {
        familyMemberName: 'Lisa Miller',
        accountType: 'Child',
        dob: '05/22/2009',
        accountStatus: 'expired',
        onResendInvitationFamilyMember: () => {},
        onCancelInvitationFamilyMember: () => {}
    }
];

const caregiversList: MyCaregiversCardProps[] = [
    {
        caregiverName: 'Alexa Smith',
        accountStatus: 'fullAccess',
        lastUpdateDate: '07/18/2023',
        onRevokeAccess: () => {}
    },
    {
        caregiverName: 'Carrie West',
        accountStatus: 'partialAccess',
        lastUpdateDate: '07/18/2023',
        onRevokeAccess: () => {}
    },
    {
        caregiverName: 'Sally Jones',
        accountStatus: 'responsePending',
        lastUpdateDate: '07/18/2023',
        onDecline: () => {},
        onAccept: () => {}
    },
    {
        caregiverName: 'Lisa Miller',
        accountStatus: 'expired',
        lastUpdateDate: '07/18/2023',
        note: 'Your invitation to join the family account has expired. Please ask your account organizer to resend an invitation.'
    },
    {
        caregiverName: 'Andrew Brown',
        accountStatus: 'declined',
        lastUpdateDate: '07/18/2023'
    }
];

// @TODO: Remove this implementation before send it to stage.
const FamilyAccount: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="family-account-page">
            <ProfileLayout
                eyebrowText={t(`pages.profile.eyebrowText`)}
                title={t(`pages.profile.familyAccount.title`)}
                heading={t(`pages.profile.familyAccount.familySection.heading`)}
                sections={[
                    {
                        heading: t(`pages.profile.familyAccount.familySection.heading`),
                        children: <FamilyAccountCardList payload={familyMembers} />
                    },
                    {
                        heading: t(`pages.profile.familyAccount.myCaregiversSection.heading`),
                        children: (
                            <>
                                <MyCaregiversCardList payload={caregiversList} />
                                <div className="mt-2">
                                    <ToastBox variant="descriptive">
                                        {t('pages.profile.familyAccount.myCaregiversSection.note')}
                                    </ToastBox>
                                </div>
                            </>
                        )
                    }
                ]}
            />
        </div>
    );
};

export default FamilyAccount;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
